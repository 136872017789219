import React from "react";

// components
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarDropdown from "./NavbarDropdown";

// assets
import romax4000 from "../../../assets/menu/thumb-romax-4000.jpg";
import romaxCompactIII from "../../../assets/menu/thumb-romax-compact-iii.jpg";
import accessories from "../../../assets/menu/thumb-accessories.png";
import approvedTools from "../../../assets/menu/thumb-approved-tools.jpg";

interface Props {
  id: number;
  imgSrc: string;
  imgAlt: string;
  title: string;
  subtitle?: string | null;
  pageUrl: string;
}

const productItems = [
  {
    id: 1,
    imgSrc: romaxCompactIII,
    imgAlt: "Romax Compact III",
    title: "Romax<br/> Compact III",
    subtitle: "Press Tool",
    pageUrl: "/tools-and-accessories/#romax-compact-III",
  },
  {
    id: 2,
    imgSrc: romax4000,
    imgAlt: "Romax 4000",
    title: "Romax<br/> 4000",
    subtitle: "Press Tool",
    pageUrl: "/tools-and-accessories/#romax-4000",
  },
  {
    id: 3,
    imgSrc: accessories,
    imgAlt: "Accessories",
    title: "Accessories",
    subtitle: "Jaws, cutters, cases and more",
    pageUrl: "/tools-and-accessories/#accessories",
  },
  {
    id: 4,
    imgSrc: approvedTools,
    imgAlt: "Approved Tools",
    title: "Approved Tools",
    pageUrl: "/tools-and-accessories/#approved-tools",
  },
];

const ToolsNav: React.FC = () => {
  return (
    <NavbarDropdown
      title="Tools &amp; Accessories"
      url="/tools-and-accessories"
      navigation={<ProductNavItems />}
      quicklinks={<QuickLinks />}
    />
  );
};

const ProductNavItems: React.FC = () => {
  return (
    <>
      {productItems.map((item: Props, index: number) => (
        <NavbarDropdownItem
          key={`product_item_${index}`}
          id={`js-scroll-tools-${item.id}`}
          imgSrc={item.imgSrc}
          imgAlt={item.imgAlt}
          title={item.title}
          subtitle={item.subtitle}
          pageUrl={item.pageUrl}
        />
      ))}
    </>
  );
};

const QuickLinks: React.FC = () => {
  return (
    <>
      {/* item */}
      <div className="c-navigation__item uk-text-center">
        <div className="uk-width-2-3 uk-height-1-1 uk-margin-auto uk-flex uk-flex-middle uk-flex-center">
          <div className="uk-flex-auto">
            <strong className="uk-margin-remove-bottom uk-display-block uk-h6 uk-text-bold">
              Tool Servicing
            </strong>
            <p className="uk-margin-remove uk-h6 uk-text-small">
              Keep your press tool in top condition
            </p>
            <a
              href="/tools-and-accessories/#tool-servicing"
              className="uk-display-inline-block uk-text-bold uk-text-small uk-link c-icon-right-arrow"
            >
              Find Out More
            </a>
          </div>
        </div>
        <div className="c-navigation__divider" />
      </div>
      {/* item */}
      <div className="c-navigation__item uk-text-center">
        <div className="uk-width-2-3 uk-height-1-1 uk-margin-auto uk-flex uk-flex-middle uk-flex-center">
          <div className="uk-flex-auto">
            <strong className="uk-margin-remove-bottom uk-display-block uk-h6 uk-text-bold">
              Tool Hire
            </strong>
            <p className="uk-margin-remove uk-h6 uk-text-small">
              Access the tools you need with convenience
            </p>
            <a
              href="/tools-and-accessories/#tool-hire"
              className="uk-display-inline-block uk-text-bold uk-text-small uk-link c-icon-right-arrow"
            >
              Find Out More
            </a>
          </div>
        </div>
        <div className="c-navigation__divider" />
      </div>
    </>
  );
};

export default ToolsNav;
